<template>
  <md-dialog
    :md-active="value"
    class="announce-modal modal"
    @md-close="closeModal"
    @md-clicked-outside="closeModal"
  >
    <div class="announce-modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>
    <div class="announce-modal-body">
      <div class="announce-modal-body-title">
        <slot name="icon">
          <MeepIconStick v-if="isNotiSuccess" class="icon-success" />
          <MeepIconExclamationMark v-else class="app-icon" />
        </slot>
        {{ title }}
      </div>
      <div class="announce-modal-body-content">
        <p class="announce-modal-body-context" v-html="description" />
      </div>
    </div>
  </md-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import MeepIconExclamationMark from "@/components/icons/MeepIconExclamationMark.vue";
import MeepIconStick from "@/components/icons/MeepIconStick.vue";

export default {
  name: "AnnounceModal",
  components: {
    MeepIconExclamationMark,
    MeepIconStick,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    subDescription: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
    isNotiSuccess: {
      tyle: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),
  },
  methods: {
    closeModal() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

.announce-modal {
  font-family: inherit;
  bottom: 50px;

  .md-dialog-container {
    width: 650px;
    box-shadow: none;
    border-radius: 27px;
    padding: 10px 34px 60px 34px;
    @include for-lg {
      width: 950px;
    }
  }

  &-header {
    .app-logo {
      svg {
        width: 105px;
      }
    }

    .app-logo-minified {
      svg {
        height: 74px;
      }
    }
  }

  &-body {
    padding: 0 20px;

    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: var(--modal-title-size-lg);
      font-family: var(--font-bold);
      margin-bottom: 15px;
      @include for-lg {
        font-size: var(--modal-title-size-xl);
      }

      .app-icon {
        width: var(--modal-icon-size-lg);
        height: var(--modal-icon-size-lg);
        margin-right: 15px;

        g {
          fill: var(--bg-primary);
        }

        path {
          stroke: var(--bg-primary);
        }

        @include for-lg {
          width: var(--modal-icon-size-xl);
          height: var(--modal-icon-size-xl);
        }
      }
      .icon-success {
        width: 88px;
        height: 88px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-context {
      margin-bottom: 10px;
      font-family: var(--font-extrabold);
      font-size: toRem(14);
      text-align: center;
      line-height: 1;
      @include for-lg {
        font-size: toRem(22);
      }

      &-sub {
        margin-top: 15px;
      }
    }

    &-question {
      font-size: 17px;
    }
  }

  &-buttons {
    margin: 0 auto;
    margin-top: 50px;

    .md-secondary {
      min-width: 80px;
    }
  }

  .announceModal__description {
    padding: 0 20px;
  }

  .md-dialog-actions {
    padding: 8px 26px 21px 24px;
  }
}
</style>
