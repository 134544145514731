<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88"><g data-name="Groupe 1071"><g data-name="Ellipse 79" fill="#f3d56d" stroke="#f3d56d" stroke-width="5"><circle cx="44" cy="44" r="44" stroke="none"/><circle cx="44" cy="44" r="41.5" fill="none"/></g><path data-name="Tracé 744" d="M25.567 45.429l12.684 15.779 24.3-36.861" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" fill="none"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconStick'
  };
</script>
