<template>
  <!-- eslint-disable -->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.462 51.109"><g fill="#8ad9fc"><text font-family="Nunito-ExtraBold, Nunito" font-size="36" font-weight="800" letter-spacing="-.025em" transform="translate(20.645 38.109)"><tspan x="0" y="0">!</tspan></text><path stroke="#8ad9fc" stroke-width="2" d="M25.232 1a24.23 24.23 0 1024.231 24.228A24.257 24.257 0 0025.232 1zm0 46.681a22.451 22.451 0 1122.453-22.453 22.477 22.477 0 01-22.453 22.453z"/></g></svg>
</template>

<script>
  export default {
    name : 'MeepIconExclamationMark'
  };
</script>
