<template>
  <div class="impot-societe">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <AnnounceModal
      v-model="isAnnounceModalOpen"
      :description="$t('simulateurs.impot-societe.modal-description')"
      @close="closeAnnounceModal"
    />

    <div class="impot-societe-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
          :title="$t('simulateurs.impot-societe.title')"
          :has-back="true"
          @back="goBack()"
          :hasActions="true"
        >
          <md-button
            class="md-primary header-action header-action-icon md-button-no-margin"
            @click="showAnnounceModal"
          >
            <MeepIconCircleQuestion class="app-icon" />
          </md-button>
        </PageHeader>
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <md-checkbox v-model="form.isPaid" class="meep-form__checkbox-big">
              {{ $t("simulateurs.impot-societe.isPaid") }}
            </md-checkbox>

            <md-checkbox
              v-model="form.isPhysicallyPresented"
              class="meep-form__checkbox-big"
            >
              {{ $t("simulateurs.impot-societe.isPhysicallyPresented") }}
            </md-checkbox>

            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.impot-societe.result") }}</label>
                <md-input
                  v-model.number="$v.form.result.$model"
                  name="result"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.result.$dirty">
                  <span v-show="!$v.form.result.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.result.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>{{
                  $t("simulateurs.impot-societe.annualTurnover")
                }}</label>
                <md-input
                  v-model.number="$v.form.annualTurnover.$model"
                  name="annualTurnover"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.annualTurnover.$dirty">
                  <span
                    v-show="!$v.form.annualTurnover.numeric"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span
                    v-show="!$v.form.annualTurnover.required"
                    class="meep-error"
                  >
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>

            <div class="meep-input meep-input-big-result">
              <p class="text">
                {{ $t("simulateurs.impot-societe.result-black") }}
              </p>
              <p class="result">{{ $$filters.formatNumber(result) }}€</p>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import MeepIconCircleQuestion from "@/components/icons/MeepIconCircleQuestion.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import PageHeader from "@/components/PageHeader";
import { required, numeric } from "vuelidate/lib/validators";
import calculatorsModel from "@/model/calculators";
import AnnounceModal from "@/components/modal/AnnounceModal.vue";

export default {
  name: "ImpotSociete",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    MeepIconCircleQuestion,
    AnnounceModal,
  },

  validations: {
    form: {
      result: { required, numeric },
      annualTurnover: { required, numeric },
    },
  },

  data() {
    return {
      form: {
        result: null,
        annualTurnover: null,
        isPaid: false,
        isPhysicallyPresented: false,
      },
      result: 0,
      impotSocieteData: [],
      isAnnounceModalOpen: false,
    };
  },

  async mounted() {
    this.impotSocieteData = await calculatorsModel.getAllSocietes();
  },

  methods: {
    calcu({ result, annualTurnover, isPaid, isPhysicallyPresented }) {
      const haveReduction =
        isPaid && isPhysicallyPresented && annualTurnover < 7630000;
      const reductionTax = this.impotSocieteData[0].coef_bas;
      const maximumReductionAmount = this.impotSocieteData[0].montant_min;
      const normalTax = this.impotSocieteData[0].coef_haut;

      if (haveReduction) {
        if (result < maximumReductionAmount) {
          this.result = result * reductionTax;
        } else
          this.result =
            maximumReductionAmount * reductionTax +
            (result - maximumReductionAmount) * normalTax;
      } else this.result = result * normalTax;
    },

    showAnnounceModal() {
      this.isAnnounceModalOpen = true;
    },

    closeAnnounceModal() {
      this.isAnnounceModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.impot-societe {
  &-content {
    &-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 25px;
    }
  }
}
</style>
